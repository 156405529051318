<template>
  <div class="bottom">

    <component v-if="current"
    :is="'Bottom'+current" 

    :bottom="bottom"
    
    @tapClick="tapClick" />
  </div>
</template>

<script>

import {getShowPage} from "@/utils/function.js"

import BottomH5 from "./BottomH5.vue"
import BottomPc from "./BottomPc.vue"
export default {

  components: {
    BottomH5,
    BottomPc
  },

  props: {
  },
  
  data() {
    return {
      current: '', // 当前渲染的组件

      bottom: {
        'but1': {'txt':'©2017 - 2025 Vtrading.com', 'src': require('@/assets/image/v3/logo_bai.png')},
        'buts':[
          {'click':'toUrl', 'src':require('@/assets/image/v3/twitter_1.png'), 'url':'https://x.com/vtradingglobal'},
          {'click':'toUrl', 'src':require('@/assets/image/v3/dianbao_1.png'), 'url':'https://t.me/VtradingEnglish'},
          {'click':'toUrl', 'src':require('@/assets/image/v3/youtube_1.png'), 'url':'https://www.youtube.com/@vtradingglobal7699'},
          // {'click':'toUrl', 'src':require('@/assets/image/v3/medium_1.png'), 'url':'https://medium.com/@Vtrading_Global'}
          {'click':'toUrl', 'src':require('@/assets/image/v3/medium_1.png'), 'url':'https://vtrading2020.medium.com/'}
          
        ],
        'but4': {'txt':'平台策略'},
        'but5': {'txt':'DCA策略'},
        'but6': {'txt':'网格策略', 'click':'toGrid'},
        'but7': {'txt':'DCA合约策略', 'click':'toDcaFuGrid'}, //
        'but8': {'txt':'网格合约策略', 'click':'toFuGrid'},
        'but9': {'txt':'信号策略'},
        'but10': {'txt':'资源'},
        'but11': {'txt':'量化学院', 'click':'toLhxy'},
        'but12': {'txt':'下载APP', 'click':'toDownload'},
        'but13': {'txt':'联系我们'},
        'but14': {'txt':'Telegram', 'click':'toUrl', 'url':'https://t.me/VtradingEnglish'},
        'but15': {'txt':'Youtube', 'click':'toUrl', 'url':'https://www.youtube.com/@vtradingglobal7699'},
        'but16': {'txt':'Mediakit', 'click':'toUrl', 'url':'https://drive.google.com/drive/folders/1fXLV-EX1M800TVpRmkaIknRSeLboa6r3'}
      }
    };
  },


  created() {
    this.initView();
  },
  
  mounted() {
    window.addEventListener('resize', this.initView);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.initView);
  },

  methods: {

    /******************************************** 其他 */
    //初始化界面判断是H5 还是  PC
    initView() {
      this.current = getShowPage('Pc', 'H5');
    },

    /******************************************** 点击 */
    //点击事件(PC、H5)
    tapClick(name, param) {
      this[name](param);
    },

    //跳转网格交易
    toGrid() {
      this.$router.push("/grid");
    },

    //跳转网格合约交易
    toFuGrid() {
      this.$router.push("/gridFutures");
    },

    //跳转DCA网格合约交易
    toDcaFuGrid() {
      this.$router.push("/dcaGridFutures");
    },

    //跳转链接
    toUrl(param) {
      window.open(param.url);
    },

    //跳转量化学院
    toLhxy() {
      this.$router.push({ path: '/newsHome' });
    },

    //跳转下载
    toDownload() {
      this.$router.push({ path: '/download' });
    }
  },
};
</script>

<style lang='scss' scoped>
@import "@/assets/sass/_themeify.scss";
.bottom {
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #181C32;
}

</style>
